
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.page__edit-document {
  padding-bottom: $gap * 2;

  td {
    padding: $base-gutter / 2;
    width: 350px;
    &:first-child {
      text-align: right;
      color: $color-grayscale-60;
    }
  }

  textarea.esmp-input-element {
    height: 100px;
  }

  .setting-form-table-wrapper {
    padding: $base-gutter;
    background: $color-white;
    border-radius: $base-gutter;
  }
}

.edit-document {
  &__right-bar {
    display: flex;
    align-items: center;
  }
  &__expander {
    display: flex;
    align-items: center;
    margin-left: $base-gutter / 2;
    width: 200px;
    &:hover {
      cursor: pointer;
    }
  }
  &__expander-text {
    display: inline-block;
    margin-bottom: -4px;
  }
  .esmp-input-element {
    background-color: $color-white;
  }
}
